import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerViewMode, BsLocaleService } from "ngx-bootstrap/datepicker";
import { ClienteObserverService } from "../../../core/services/cliente/cliente-observer.service";
import { SearchStorageService } from "../../../core/services/search/search-storage.service";
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
defineLocale('es', esLocale);

@Component({
  selector: 'app-data-picker',
  templateUrl: './data-picker.component.html',
  styleUrls: ['./data-picker.component.scss']
})

export class DataPickerComponent implements OnInit, OnDestroy {
  //fecha = new Date();
  @Input() bsValue: Date;

  //bsValue: Date = new Date(this.fecha.getFullYear(), this.fecha.getMonth());
  minMode: BsDatepickerViewMode = 'month';
  bsConfig: Partial<BsDatepickerConfig>;
  constructor(
    private clienteObserver: ClienteObserverService,
    private searchStorageService: SearchStorageService,
    private localeService: BsLocaleService) {
    this.localeService.use('es');
    console.log("Fecha de inicio en el contructor", this.bsValue);
    this.bsValue = new Date();
  }

  ngOnInit(): void {
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode,
      containerClass: 'theme-green my-datepicker-container',
      dateInputFormat: 'MMMM YYYY'
    });
    console.log("Date ", this.bsValue);
    if (!this.bsValue) {
      console.log("Desde Data picker ", this.bsValue);
      this.bsValue = this.searchStorageService.getFechaSeleccionada();
    }
  }

  cambioFecha() {
    this.searchStorageService.setFechaSeleccionada(this.bsValue);
    console.log("Fecha de inicio");
    console.log("bsValue mes ", this.bsValue.getMonth());
    console.log("bsValue año", this.bsValue.getFullYear());
    localStorage.setItem('anio', this.bsValue.getFullYear() + "");
    localStorage.setItem('mes', String(this.bsValue.getMonth() + 1));
    this.clienteObserver.clienteOb(this.searchStorageService.getBusquedaMensual());
    this.clienteObserver.getClientes$().subscribe(dataCliente => {
      console.log("dataCliente", dataCliente);
    });
  }

  ngOnDestroy(): void {
    this.clienteObserver.getUnsuscirbe()
  }


}
