import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Empresa } from "../../../../core/services/cliente/cliente.interface";
import { TokenStorageService } from "../../../../core/services/auth/token-storage.service";
import { Usuario } from "../../../../core/services/usuario/usuario.interfaces";
import { UsuarioService } from '../../../../core/services/usuario/usuario.service';

@Component({
  selector: 'app-select-empresa',
  templateUrl: './select-empresa.component.html',
  styleUrls: ['./select-empresa.component.scss']
})
export class SelectEmpresaComponent implements OnInit {
  empresas: Empresa[];
  formSelectCliente: FormGroup;

  constructor(
    private tokenService: TokenStorageService,
    private fb: FormBuilder,
    private modal: NgbModal,
    private storageService: TokenStorageService,
    public activeModal: NgbActiveModal,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.getEmpresaForUser();
    this.buildFormSelect();
  }

  getEmpresaForUser(): void {
    console.log('getEmpresaForUser');
    const user: Usuario = this.storageService.getUser();
    console.log('getEmresaUsuario', user.assginedEmpresas);
    if (user.assginedEmpresas.length > 0) {
      this.empresas = user.assginedEmpresas;
      console.log('empresas', this.empresas);
      if (user.assginedEmpresas.length === 1) {
        this.setEmpresaInLocalStorage(user.assginedEmpresas[0]);
        this.tokenEmpresa();
        setTimeout(() => {
          this.activeModal.close({ seleccionado: true, rfc: user.assginedEmpresas[0].rfc });
        }, 100);
      }
    }
  }

  eleccionEmpresa(): void {
    console.log("Eleccion cliente");
    const selectedRfc = this.formSelectCliente.get('selectEmpresa').value;
    console.log('rfc seleccionada', selectedRfc);
    const selectedEmpresa = this.empresas.find(empresa => empresa.rfc === selectedRfc);
    if (selectedEmpresa) {
      console.log('empresa seleccionada', selectedEmpresa);
      this.setEmpresaInLocalStorage(selectedEmpresa);
      this.tokenEmpresa();
      this.activeModal.close({ seleccionado: true, rfc: selectedRfc });
    }
  }

  close(): void {
    this.modal.dismissAll();
  }

  buildFormSelect(): void {
    this.formSelectCliente = this.fb.group({
      selectEmpresa: [this.empresas.length > 0 ? this.empresas[0].rfc : null]
    });
  }

  async tokenEmpresa(): Promise<void> {
    const empresaId = Number(localStorage.getItem("idEmpresa"));
    console.log(" empresa " + empresaId);
    try {
      const response = await this.usuarioService.tokenByEmpresa(empresaId).toPromise();
      console.log("Respuesta recibida:", response);
      const token = response.token;
      console.log("Token recibido:", token);
      localStorage.setItem("tokenByEmpresa", token);
      this.tokenService.saveToken(token);
    } catch (error) {
      console.error("Error al obtener el token:", error);
      // Manejo del error aquí
    }
  }

  private setEmpresaInLocalStorage(empresa: Empresa): void {
    localStorage.setItem("rfc", empresa.rfc);
    localStorage.setItem("idEmpresa", String(empresa.id));
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;
    if (!localStorage.getItem("anio")) {
      localStorage.setItem("anio", String(currentYear));
    }
    if (!localStorage.getItem("mes")) {
      if (currentMonth === 1) {
        currentMonth = 12;
        localStorage.setItem("anio", String(currentYear - 1));
      } else {
        currentMonth -= 1;
      }
      localStorage.setItem("mes", String(currentMonth));
    }
  }
}
