import { LOCALE_ID, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ChartistModule } from "ng-chartist";
import { ChartsModule } from "ng2-charts";
import { PieComponent } from "./components/pie/pie.component";
import { DonaComponent } from "./components/dona/dona.component";
import { LineChartComponent } from "./components/line-chart/line-chart.component";
import { NavbarAdminComponent } from './components/navbar-admin/navbar-admin.component';
import { BarChartHorizontalComponent } from "./components/bar-chart-horizontal/bar-chart-horizontal.component";
import { BarChartComponent } from "./components/bar-chart/bar-chart.component";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { BreadcrumbModule } from 'angular-crumbs';
import { DataPickerComponent } from './components/data-picker/data-picker.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PieEfosComponent } from "./components/pie-efos/pie-efos.component";
import { BarChartEfosComponent } from "./components/bar-chart-efos/bar-chart-efos.component";
import { ListConciliacionDocsComponent } from './components/list-document/list-conciliacion-docs/list-conciliacion-docs.component';
import { ConcilicacionDocNotFoundComponent } from './components/list-document/concilicacion-doc-not-found/concilicacion-doc-not-found.component';
import { ConciliacionDocDifStateComponent } from './components/list-document/conciliacion-doc-dif-state/conciliacion-doc-dif-state.component';
import { DetalleDocConciliadoComponent } from './components/list-document/detalle-doc-conciliado/detalle-doc-conciliado.component';
import { ConciliacionDocAmountComponent } from './components/list-document/conciliacion-doc-amount/conciliacion-doc-amount.component';
import { WorkingOnPageComponent } from './components/working/working-on-page/working-on-page.component';
import { LoaderCirculeComponent } from './components/loader-circule/loader-circule.component';

@NgModule({
  declarations: [
    LoaderCirculeComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    PieComponent,
    PieEfosComponent,
    BarChartEfosComponent,
    DonaComponent,
    LineChartComponent,
    NavbarAdminComponent,
    BarChartComponent,
    BarChartHorizontalComponent,
    BreadcrumbsComponent,
    DataPickerComponent,
    ListConciliacionDocsComponent,
    ConcilicacionDocNotFoundComponent,
    ConciliacionDocDifStateComponent,
    DetalleDocConciliadoComponent,
    ConciliacionDocAmountComponent,
    WorkingOnPageComponent,
    LoaderCirculeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    ChartistModule,
    ChartsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    LoaderCirculeComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    PieComponent,
    BarChartComponent,
    PieEfosComponent,
    BarChartEfosComponent,
    BarChartHorizontalComponent,
    DonaComponent,
    LineChartComponent,
    NavbarAdminComponent,
    BreadcrumbsComponent,
    DataPickerComponent,
  ],
})
export class SharedModule { }
