import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-circule',
  templateUrl: './loader-circule.component.html',
  styleUrls: ['./loader-circule.component.scss']
})
export class LoaderCirculeComponent implements OnInit {

  @Input() type: string ='';
  
  constructor() { }

  ngOnInit(): void {
  }

}
