<div class="table-responsive">
  <h4 style="font-weight: bold; text-align: center;">
    DIFERENCIA DE ESTADOS
    <i class="mdi mdi-file-document-box" style="color: #73b72b;" type="button" ngbTooltip="Descarga de Reporte"
      (click)="descarga()"></i>
  </h4>
  <hr class="border border-tertiary border-1 opacity-50">
  <div class="ml-2">
    <label class="custom-label"> {{ this.totalRegistros}} Registros seleccionados</label>
  </div>
  <div class="pagination-container d-flex justify-content-between">

    <div class="form-group ml-1 mr-3">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Buscar documentos" [(ngModel)]="searchTerm"
          (input)="filterDocs()" />
        <div class="input-group-append">
          <button type="button" class="btn btn-search">
            <img src="assets/images/file-icons/64/search.png" alt="buscar">
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="custom-label"> Registros por página:</label>
    <select class="custom-select" [(ngModel)]="registrosPorPagina" name="registrosPorPagina"
      (change)="onPageSizeChange($event.target.value)">
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
    </select>
  </div>

  <table class="table table-sm table-striped">
    <thead class="head-documents">
      <tr>
        <th colspan="8" class="text-center head-title"></th>
      </tr>
      <tr class="text-right head-title">
        <th colspan="8">
          <h4>Conciliar la cantidad de {{ documentosSeleccionados.length }} Documentos
            <i class="mdi mdi-alert text-danger" type="button" ngbTooltip="Conciliar Todos" (click)="docsValid()"></i>
          </h4>
        </th>
      </tr>
      <tr class="text-center head-column">
        <th scope="col" style="color: white;">UUID</th>
        <th scope="col" style="color: white;">CANAL</th>
        <th scope="col" style="color: white;">ESTADO</th>
        <th scope="col" style="color: white;">RFC</th>
        <th scope="col" style="color: white;">RECEPTOR</th>
        <th scope="col" style="color: white;">TOTAL</th>
        <th scope="col" style="color: white;">OPCIONES</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let group of filteredDocs">
        <tr class="text-center" [class.highlight]="group.expanded" (click)="toggleGroup(group.uuid)">
          <th class="text-center">
            <span [ngClass]="{'arrow': true, 'arrow-down': group.expanded, 'arrow-right': !group.expanded}"></span>
            <span class="uuid-text">{{ group.uuid }}</span>
          </th>
          <td class="text-center">{{ group.docs[0].canal }}</td>
          <td class="text-center">{{ group.docs[0].estado | uppercase}}</td>
          <td class="text-center">{{ group.docs[0].rfcEmisor }}</td>
          <td class="text-center">{{ group.docs[0].nombreReceptor }}</td>
          <td class="text-center">{{ group.docs[0].comprobante.total | currency}}</td>
          <td class="text-center">
            <i class="mdi mdi-update" style="color: gray;" type="button" ngbTooltip="Actualizar estado del documento"
              (click)="conciliacion(group.docs[0])"></i>
          </td>
        </tr>
        <ng-container *ngIf="group.expanded">
          <tr *ngFor="let doc of group.docs.slice(1)" class="text-center highlight">
            <th scope="row"></th>
            <td class="text-center">{{ doc.canal }}</td>
            <td class="text-center">{{ doc.estado | uppercase}}</td>
            <td class="text-center">{{ doc.rfcEmisor }}</td>
            <td class="text-center">{{ doc.nombreReceptor }}</td>
            <td class="text-center">{{ doc.comprobante.total | currency }}</td>
            <td class="text-center">
              <i class="mdi mdi-update" style="color: gray;" type="button" ngbTooltip="Actualizar estado del documento"
                (click)="conciliacion(doc)"></i>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<div class="pagination-wrapper">
  <div class="pagination-container">
    <div class="pagination-controls">
      <div class="btn-toolbar mb-1" role="toolbar">
        <div class="btn-group-sm ml-1" role="group">
          <button type="button" class="btn boton-verde-personalizado icono-blanco" (click)="paginaInicial()"
            [disabled]="paginaActual === 1">
            <b><span><i class="mdi mdi-skip-backward"></i></span></b>
          </button>
        </div>
        <div class="btn-group-sm ml-1" role="group">
          <button type="button" class="btn boton-verde-personalizado icono-blanco" (click)="paginaAnterior()"
            [disabled]="paginaActual === 1">
            <b><span><i class="mdi mdi-skip-previous"></i></span></b>
          </button>
        </div>
        <div class="form-group ml-1">
          <input type="number" class="form-control form-control-sm pagination-input" [(ngModel)]="paginaActual"
            name="paginaActual" min="1" [max]="totalPaginas" (change)="getDocsStatus(requetEmitido, tipoComprobante)"
            style="width: 60px; text-align: center;">
        </div>
        <div class="btn-group-sm ml-1" role="group">
          <button type="button" class="btn boton-verde-personalizado icono-blanco" (click)="paginaSiguiente()"
            [disabled]="paginaActual === totalPaginas">
            <b><span><i class="mdi mdi-skip-next"></i></span></b>
          </button>
        </div>
        <div class="btn-group-sm ml-1" role="group">
          <button type="button" class="btn boton-verde-personalizado icono-blanco" (click)="paginaFinal()"
            [disabled]="paginaActual === totalPaginas">
            <b><span><i class="mdi mdi-skip-forward"></i></span></b>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>