import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from '@angular/common';
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from "rxjs/operators";
import { Conciliacion } from "src/app/core/services/nomina/interfaces/nomina-details";
import { ConciliacionService } from "src/app/core/services/conciliacion/conciliacion.service";
import { ReporteService } from "src/app/core/services/reporte/reporte.service";
import * as FileSaver from "file-saver";

import Swal from "sweetalert2";
import { ClienteObserverService } from "src/app/core/services/cliente/cliente-observer.service";
import { FormControl } from "@angular/forms";
import { RequestDetails } from 'src/app/core/services/nomina/interfaces/beans.interfaces';
import { NominaDetailsService } from 'src/app/core/services/nomina/nomina-details.service';

@Component({
  selector: 'app-concilicacion-doc-not-found',
  templateUrl: './concilicacion-doc-not-found.component.html',
  styleUrls: ['./concilicacion-doc-not-found.component.scss']
})
export class ConcilicacionDocNotFoundComponent implements OnInit, OnDestroy {
  typeAlert: string;
  messageAlert: string;
  buscadorDocumento = new FormControl();
  @Input() documentos: Conciliacion[] | any = [];
  private OnDestroy$ = new Subject();
  docsFiltered: Conciliacion[];
  documentosSeleccionados: string[] = [];
  @Output() exiteConciliacion = new EventEmitter<boolean>();
  @Input() sentido: string;
  searchTerm: String = '';
  paginaActualAnterior: number;

  //PAGINADOR
  registrosPorPagina = 10;
  paginaActual = 1;
  totalRegistros = 0;
  totalPaginas = 0;
  requetEmitido: any;
  tipoComprobante: string = null;
  documentosPac: Conciliacion[] = [];

  constructor(
    private router: Router,
    private conciliacionService: ConciliacionService,
    private reporteService: ReporteService,
    private clienteOb: ClienteObserverService,
    private clienteObservador: ClienteObserverService,
    private route: ActivatedRoute,
    private nominaDetails: NominaDetailsService,
    private location: Location,
  ) {
    this.tipoComprobante = this.route.snapshot.paramMap.get("tipoComprobante");


  }

  ngOnInit(): void {
    console.log("documentos en componente hijo: ", this.documentos);
    this.filterDocs();
    this.setFormatFecha();

    this.clienteObservador.getClientes$().subscribe(dataCliente => {
      this.requetEmitido = new RequestDetails();
      this.requetEmitido.rfc = dataCliente.empresa;
      this.requetEmitido.anio = dataCliente.anio;
      this.requetEmitido.mes = dataCliente.mes.id;
        this.getDocumentosCount(this.requetEmitido,this.tipoComprobante);
    });
  }




  filterDocs() {
    if (!this.searchTerm) {
      this.docsFiltered = this.documentos;
    } else {
      this.docsFiltered = this.documentos.filter((value) => value.uuid.includes(this.searchTerm.toLowerCase()));
    }
  }

  visualizar(uuid: string): void {
    console.log("detalle", uuid);
    localStorage.setItem("uuid", uuid);
    this.router.navigate(["home/factura-empresa/detalle"]);
    //this.router.navigate(["home/nomina-empresa-emitidas/dashboard-empresa/pac/vigente/detalle"]);
  }

  conciliacion(documento: Conciliacion) {
    this.statusOK(documento);
  }


  insertarConciliacion(documento: Conciliacion) {
    this.clienteOb.getClientes$().subscribe((dataClient) => {
      this.conciliacionService
          .SatAzurian(
              documento.uuid,
              dataClient.empresa,
              dataClient.anio,
              dataClient.mes.id
          )
          .subscribe((data) => {
            console.log(
                "Se va a cargar el documento en Azurian",
                data.httpStatus
            );
            if (data.httpStatus === 201) {
                this.eliminarValor(documento); //elimina el documento de la lista
              this.existeConciliacion();
            } else {
              this.showMessageAlert("info", data.messageDetail);
            }
          });
    });
  }
  showMessageAlert(tipo: string, mensaje: string) {
    this.messageAlert = mensaje;
    this.typeAlert = tipo;
    setTimeout(() => (this.messageAlert = null), 5000);
  }


  ngOnDestroy(): void {
    this.OnDestroy$.next();
  }

  regresar(): void {
    this.location.back()
  }

  descarga() {
    this.reporteService.descargaReporte(this.documentos).subscribe((data) => {

      if (data.httpStatus === 200) {
        let fileBlob = this.base64ToArrayBuffer(data.reporte);
        let archivo = new Blob([fileBlob], { type: "application/xlsx" });
        console.log(archivo);
        FileSaver.saveAs(archivo, "Reporte.xlsx");
      } else {
        this.showMessageAlert("info", data.messageDetail);
      }
    });
  }

  setFormatFecha() {

    this.documentos.forEach(item => {
      item.fechaEmision = item.fechaEmision.split('T')[0];
      item.fechaRegistro = item.fechaRegistro.split('T')[0];
      item.fechaTimbrado = item.fechaTimbrado.split('T')[0];
    });

    console.log('Documento con nuevo formato de fecha: ', this.documentos);
  }

  base64ToArrayBuffer(base64: any): any {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  contador(): boolean {
    if (this.documentosSeleccionados.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  docsValid() {
    console.log("conciliacionMasiva desde empresa");
    Swal.fire({
      title: "Documento válidos ",
      text: "¿Seguro que todos los documentos seleccionado, son válidos?",
      icon: "warning",
      confirmButtonColor: " #73b72b",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then((resultado) => {
      if (resultado.value) {
        this.conciliacionMasiva(this.documentosSeleccionados);
      }
    });
  }

  conciliacionMasiva(uuids: string[]) {
    this.clienteOb.getClientes$().subscribe((dataClient) => {
      console.log("Datos del cliente consultado  : ", dataClient);
      this.conciliacionService.SatAzurianMasuvo(uuids, dataClient.empresa,
        dataClient.anio,
        dataClient.mes.id).subscribe((data) => {
          console.log("Status al cargar el archivo en azurian :", data.httpStatus);
          if (data.httpStatus === 201) {
            this.showMessageAlert("info", data.messageDetail);
            this.statusOK2();
            //this.router.navigate(["home/nomina-empresa-emitidas/dashboard-empresa"]);

          } else {
            this.showMessageAlert("info", data.messageDetail);
          }
        });
    });
  }



  selectAll(event: any) {
    console.log("valor del event :", event.target.checked);
    if (event.target.checked) {
      this.documentos.map((doc) => {
        doc.checked = true;
        this.documentosSeleccionados.push(doc.uuid);
      });
    } else {
      this.documentosSeleccionados = [];
      this.documentos.map((doc) => {
        doc.checked = false;
      });
    }
  }

  statusOK(documento: Conciliacion) {
    Swal.fire({
      title: "Conciliado con exito",
      icon: "success",
      confirmButtonColor: " #73b72b",
      confirmButtonText: "Salir",
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        this.insertarConciliacion(documento);
      }
    });
  }

  eliminarValor(documento: Conciliacion) {
    this.documentos.splice(
      this.documentos.findIndex((item) =>
        item.uuid === documento.uuid
      ), 1
    );
    localStorage.setItem("documentosSat", JSON.stringify(this.documentos));
    this.docsFiltered = this.documentos;

    this.limpiarBusqueda();

  }

  eliminarValores() {
    for (let index = 0; index < this.documentosSeleccionados.length; index++) {

      this.documentos.splice(
        this.documentos.findIndex((item) =>
          item.uuid === this.documentosSeleccionados[index]
        ), 1
      );
     // localStorage.setItem("documentosSat", JSON.stringify(this.documentos));
      this.docsFiltered = this.documentos;
    }
    this.limpiarBusqueda();

  }

  statusOK2() {
    Swal.fire({
      title: "Conciliado con exito",
      icon: "success",
      confirmButtonColor: " #73b72b",
      confirmButtonText: "Salir",
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        this.eliminarValores();

      }
    });
  }

  limpiarBusqueda() {
    this.buscadorDocumento.setValue('');
  }

  existeConciliacion(): void {
    console.log("documentos", this.documentos.length);
    if (this.documentos.length > 0) {
      console.log("Existe conciliacion emitimos true", this.documentos.length, "true");
      this.exiteConciliacion.emit(true);
    } else {
      console.log("No existe conciliacion ", this.documentos.length, "false");

      this.exiteConciliacion.emit(false);
    }
  }

 
 

 

  /**************************** VALIDAR PAGINA ACTUAL ****************************/
  validarPaginaActual() {
    if (this.paginaActual < 1) {
      this.paginaActual = 1;
    } else if (this.paginaActual > this.totalPaginas) {
      this.paginaActual = this.totalPaginas;
    }
    this.getDocumentos(this.requetEmitido, this.tipoComprobante);
  }

  total() {

  }

  getDocumentos(request: RequestDetails, tipoComprobante: string) {
    console.log("request", tipoComprobante);
    console.log(" sentidox "+this.sentido);
    if (!this.sentido) {
      this.sentido = localStorage.getItem("sentidoConciliacion");
      console.log("entro");
    } else {
      console.log("else");
        localStorage.setItem("sentidoConciliacion", this.sentido);
    }
    this.nominaDetails.getConciliacionDocumentos(request, 'pac', tipoComprobante, this.paginaActual - 1, this.registrosPorPagina, this.sentido).subscribe(data => {
      this.documentos = data;
      this.docsFiltered = this.documentos;
      console.log("documentos inexistente en el pac", this.documentosPac.length);
    });
  }

  getDocumentosCount(request: RequestDetails, tipoComprobante: string) {
    if (!this.sentido) {
      this.sentido = localStorage.getItem("sentidoConciliacion");
      console.log("entro");
    } else {
      console.log("else");
        localStorage.setItem("sentidoConciliacion", this.sentido);
    }
    this.nominaDetails.getConciliacionDocumentosCount(request, 'pac', tipoComprobante, this.sentido).subscribe(data => {
      this.totalRegistros = data['numeroRegistros'];
      this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPorPagina);
    });
  }

  paginaInicial() {
    this.paginaActual = 1;
    this.paginaActualAnterior = this.paginaActual;

    this.getDocumentos(this.requetEmitido, this.tipoComprobante);
  }

  paginaAnterior() {
    if (this.paginaActual > 1) {
      this.paginaActual--;
      this.paginaActualAnterior = this.paginaActual;

      this.getDocumentos(this.requetEmitido, this.tipoComprobante);
    }
  }

  paginaSiguiente() {
    if (this.paginaActual < this.totalPaginas) {
      this.paginaActual++;
      this.getDocumentos(this.requetEmitido, this.tipoComprobante);
    }
  }

  paginaFinal() {
    this.paginaActual = this.totalPaginas;
    this.getDocumentos(this.requetEmitido, this.tipoComprobante);
  }

  onPageSizeChange(size: number) {
    this.registrosPorPagina = size;
    this.paginaActual = 1;
    this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPorPagina);
    this.getDocumentos(this.requetEmitido, this.tipoComprobante);
  }

  showInput() {
    document.getElementById('searchInput').style.display = 'block';
  }
  
  hideInput() {
    const input = document.getElementById('searchInput');
    if (!input.matches(':focus')) {
      input.style.display = 'none';
    }
  }
  
  keepInputVisible() {
    document.getElementById('searchInput').style.display = 'block';
  }

  validarPagina(): void {
    if (this.paginaActual < 1 || this.paginaActual > this.totalPaginas) {
      this.paginaActual = this.paginaActualAnterior || 1;  // Volver a la página anterior si es inválida
    }
    this.getDocumentos(this.requetEmitido, this.tipoComprobante);
  }
  

  
}


