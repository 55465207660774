import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { NominaDetailsService } from 'src/app/core/services/nomina/nomina-details.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})

export class BarChartComponent implements OnInit {
  @Input() intervalo = 0;
  @Input() labels = 0;
  @Input() diagnostico = false;
  @Input() showLegend: boolean = true;
  private defaultColors = [
    { backgroundColor: '#72B4A4', hoverBackgroundColor: '#72B4A4' },
    { backgroundColor: '#F8D86D', hoverBackgroundColor: '#F8D86D' },
    { backgroundColor: '#F2AA5E', hoverBackgroundColor: '#F2AA5E' },
    { backgroundColor: '#29B6F6', hoverBackgroundColor: '#29B6F6' },
    // Agrega más colores si es necesario
  ];
  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      xAxes: [{
        // Configuraciones adicionales aquí si son necesarias
      }],
      yAxes: [{
        ticks: {
          min: 0,
          autoSkip: false,
          stepSize: this.intervalo,
          fontColor: '#878e94',
          beginAtZero: true,
          maxRotation: 0,
          minRotation: 0
        },
        gridLines: {
          color: '#b9c0d3',
          zeroLineColor: '#b9c0d3',
          display: false,
          drawBorder: false
        }
      }],
    },
    legend: {
      //display: this.showLegend, // Ajusta basado en la entrada showLegend
    },
    tooltips: {
      enabled: true, // Desactiva los tooltips aquí
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
    
          // Reemplaza "AZURIAN" por "ERP"
          if (label.includes('AZURIAN')) {
            label = label.replace('AZURIAN', 'PAC');
          }
    
          return `${label}: ${tooltipItem.yLabel}`;
        }
      }
    },
    plugins: {
      datalabels: {
        display: false // Asegúrate de que los datalabels estén desactivados si están siendo usados
      }
    }
  };
  @Input() barChartLabels: Label[] = [
  ];
  barChartType: ChartType = 'bar';

  barChartLegend = true;
  barChartPlugins = [];

  @Input() barChartData: ChartDataSets[] = [ ];

  constructor(private route: Router, nominaDetails: NominaDetailsService) {


  }
  ngOnInit(): void {
    setTimeout(() => {
      this.updateChartSettings();
      this.applyDefaultColors();
    }, 1000);
  }

  private updateChartSettings() {
    this.barChartOptions.scales.yAxes[0].ticks.stepSize = this.intervalo;
  }
  public onMouseEnter(event: Event, hoverColor: string): void {
    const element = event.target as HTMLElement;
    element.style.backgroundColor = hoverColor;
  }

  public onMouseLeave(event: Event, originalColor: string): void {
    const element = event.target as HTMLElement;
    element.style.backgroundColor = originalColor;
  }

  private applyDefaultColors() {
    this.barChartData.forEach((dataset, index) => {
      const color = this.defaultColors[index];
      dataset.backgroundColor = color.backgroundColor;
      dataset.hoverBackgroundColor = color.hoverBackgroundColor;
    });
  }


}
