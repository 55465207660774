import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';

@Component({
  selector: "app-pie",
  templateUrl: "./pie.component.html",
  styleUrls: ["./pie.component.scss"],
})
export class PieComponent implements OnInit {
  public pieChartOptions: ChartOptions;
  labelsPieAux: any = {};
  public pieChartPlugins = [];
  hiddenIndexes: Set<number> = new Set(); 
  originalData: number[] = [];
  constructor() {}

  ngOnInit(): void {
    this.originalData = [...(this.pieChartData[0] as number[])]; 
    this.setChartOptions();
    console.log("labelpies " + JSON.stringify(this.labelsPie));
    this.labelsPieAux = this.labelsPie.map(() => '');

    console.log("ngOnInit");
  }

  @Input() efos = false;

  @Input() labelsPie: Label[] = [
    /* "SAT", "AZURIAN", "ERP" */
  ];

  @Input() pieChartData: SingleDataSet[] = [
    /* [300, 500, 100], */
  ];

  public pieChartType: ChartType = "pie";

  @Input() chartColors: Array<any> = [
    /*{
      backgroundColor: ["#DC4C4C", "#F8D86D", "#F2AA5E", "#DC4C4C"],
      hoverBackgroundColor: [  "#088395","#d3ebc7","#24527A", "#F6D7A7"],
      borderWidth: 2,
    },*/
  ];

  ngOnChanges(changes: SimpleChanges): void {
    this.pieChartPlugins = [];

    if (changes.efos) {
      this.setChartOptions(); // Reconfigura las opciones del gráfico si efos cambia
    }
  }

  private setChartOptions() {
    if (this.efos) {
      this.pieChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: '#fff',
            font: {
              size: 7,
            },
            formatter: (value, ctx) => {
              const dataArr = ctx.chart.data.datasets[0].data as number[];
              const sum = dataArr.reduce((acc, cur) => acc + cur, 0);
              const percentage = ((value / sum) * 100).toFixed(2) + '%';
              return percentage;
            }
          }
        }
      };
    } else {
      this.pieChartPlugins = [];
      this.pieChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
          },
          datalabels: {
            display: false
          }
        }
      };

      
      this.pieChartOptions.onClick = (event: any, elements: any[]) => {
        if (elements.length > 0) {
          console.log("Evento de clic detectado en elementos:", elements);

          const index = elements[0]?._index ?? -1;

          console.log("Índice seleccionado:", index);

          if (index !== -1) {
            if (this.hiddenIndexes.has(index)) {
              this.hiddenIndexes.delete(index); 
            } else {
              this.hiddenIndexes.add(index); 
            }

            this.updateChart();
          }
        }
      };
    }
  }

  
  updateChart() {
    if (this.pieChartData.length > 0) {
        const filteredData = this.originalData.map((value, index) =>
            this.hiddenIndexes.has(index) ? 0 : value 
        );

        this.pieChartData = [[...filteredData]];
        this.pieChartData = [...this.pieChartData]; 
    }
}

  toggleSegment(index: number) {
    if (this.hiddenIndexes.has(index)) {
        this.hiddenIndexes.delete(index); 
    } else {
        this.hiddenIndexes.add(index); 
    }
    this.updateChart();
}


}
