 <!-- Loader centrado -->
  <div *ngIf="type == 'point'">
    <div class="loader-overlay">
        <div class="loader-circule"></div>
    </div>
  </div>

  <div *ngIf="type == 'circule'">
    <div class="loader-overlay">
        <div class="loader-graph-pastel"></div>
    </div>
  </div>
